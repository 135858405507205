import {sourceTypeCategories} from '@common/utils/consts.source';

export default {
  getMetaInfo(vm) {
    const surname =
      vm.clanDetailedState && (vm.clanDetailedState.name_hant === vm.$route.query.word || vm.isTemplate)
        ? `${vm.clanDetailedState.name_hant} ${vm.clanDetailedState.pinyin}`
        : vm.$route.query.word;
    let metaObj = {
      title: `${surname} Last Name Origins, Meaning, and Surname Distribution`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `Explore the meaning and story behind the ${surname} name. See where ${surname}s live and come from, and discover ${surname} records.`,
        },
        {vmid: 'title', name: 'title', content: `${surname} Last Name Origins, Meaning and Surname Distribution`},
        {
          vmid: 'og:title',
          property: 'og:title',
          name: 'og:title',
          content: `${surname} Last Name Origins, Meaning and Surname Distribution`,
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
      ],
    };

    let clan_data = vm.clanDetailedState;

    if (clan_data && clan_data.clan_image_url) {
      metaObj.meta.push({
        vmid: 'og:image:secure_url',
        property: 'og:image:secure_url',
        content: clan_data.clan_image_url,
      });
      metaObj.meta.push({
        vmid: 'og:image',
        property: 'og:image',
        content: clan_data.clan_image_url,
      });
      metaObj.meta.push({
        vmid: 'image',
        name: 'image',
        property: 'image',
        content: clan_data.clan_image_url,
      });
      metaObj.meta.push({
        vmid: 'twitter:image',
        name: 'twitter:image',
        property: 'twitter:image',
        content: clan_data.clan_image_url,
      });
    }
    return metaObj;
  },
  getLinks(vm) {
    if (vm.clanDetailedState.external_links && vm.clanDetailedState.external_links.length) {
      return vm.clanDetailedState.external_links;
    }
    return [
      {
        title: '8 Things You Didn’t Know About Your Chinese Surname',
        full_link: 'https://www.mychinaroots.com/blog/genealogy/8-things-you-didnt-know-about-your-chinese-surname/',
        image_url: vm.$getAsset('/assets/services/script_wall_optimized2.jpg'),
      },
    ];
  },
  clearClanDetails(vm) {
    if (
      vm.clanDetailedState &&
      vm.clanDetailedState.name_ch !== vm.$route.query.word &&
      vm.clanDetailedState.name_hant !== vm.$route.query.word
    ) {
      vm.$store.commit('setClanDetailedState', null);
      vm.surnameNotFound = false;
    }
  },
  init(vm) {
    vm.$scrollTo({x: 0});
    vm.$store.dispatch('getClanDetailsAction', vm.$route.query.word).catch(err => {
      vm.surnameNotFound = true;
    });

    const searchMentionsPromise = vm.$store.dispatch('searchAllRecordsSilentAction', {
      surname: vm.$route.query.word,
      only_meta: true,
    });
    const searchSourcesPromise = vm.$store.dispatch('searchSourcesInBackgroundAction', {
      clan_name: vm.$route.query.word,
      category_id: sourceTypeCategories.CATEGORY_ZUPUS,
      only_meta: true,
    });
    Promise.all([searchMentionsPromise, searchSourcesPromise]).then(responses => {
      const meta = {...responses[0].meta, sourcesMeta: responses[1].meta};
      vm.searchRecordsMeta = {...meta, surname: vm.$route.query.word};
    });

    if (!vm.$store.getters.searchCategoriesListState.length) {
      vm.$store.dispatch('fetchSearchCategoriesListAction');
    }
  },
};
