<template>
  <div class="section-records loading" v-if="showLoading">
    <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
  </div>
  <div class="section-records" v-else-if="meta && meta.total_count">
    <h5>What {{ pinyin }} family records are available?</h5>
    <div class="records-container">
      <record-category-block
        :item="item"
        v-for="item in displayItems"
        :key="item.id"
        :surname="surname"
      ></record-category-block>
    </div>
  </div>
</template>

<script>
import RecordCategoryBlock from '@common/elements/layouts/RecordCategoryBlock';
import orderBy from 'lodash/orderBy';

export default {
  props: {
    pinyin: String,
    surname: String,
    meta: Object,
    loading: Boolean,
  },
  computed: {
    displayItems() {
      return orderBy(this.$store.getters.searchCategoriesListState, ['is_highlighted', 'priority'], ['desc', 'asc'])
        .map(category => {
          let facet = this.meta.facets.category_id[category.id];
          if (facet) {
            return {...category, persons_count: facet.count, books_count: null};
          }
          facet = this.meta.sourcesMeta.facets.category_id[category.id];
          return facet ? {...category, persons_count: null, books_count: facet.count} : null;
        })
        .filter(item => item && (item.persons_count || item.books_count));
    },
    showLoading() {
      const metaSurname = this.meta && this.meta.surname;
      const querySurname = this.$route.query.word;
      const byMeta = metaSurname && querySurname && metaSurname !== querySurname;
      return this.loading || byMeta;
    },
  },
  components: {RecordCategoryBlock},
  name: 'SectionRecords',
};
</script>

<style lang="scss" scoped>
.section-records {
  .records-container {
    width: 100%;
    margin-top: 12px;
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .records-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
