<template>
  <div class="ancestor-survey-page">
    <div class="content">
      <div class="form">
        <stand-out-block class="component_content">
          <h4>What are your goals?</h4>
          <div class="bold">I'd like to...</div>
          <div class="interests-container">
            <div v-for="option in interestsOptions" :key="option.value" class="interest">
              <input v-model="interests" :id="option.value" :value="option.label" type="checkbox" />
              <label :for="option.value">{{ option.label }}</label>
            </div>
          </div>
          <div class="comment-container">
            <textarea id="comments" v-model="comment" placeholder="Comments"></textarea>
          </div>
          <mcr-button @click="submit" :loading="loading">Send</mcr-button>
        </stand-out-block>
      </div>
      <contact-us-testimonial></contact-us-testimonial>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import {mapGetters} from 'vuex';

import ContactUsTestimonial from '@/components/common/ContactUsTestimonial';

export default {
  data() {
    return {
      interests: [],
      comment: '',
      interestsOptions: [
        {value: 'tips', label: 'Get tips on how to do Chinese genealogy research'},
        {value: 'find-records', label: 'Find records of my ancestor(s)'},
        {value: 'honor', label: 'Honor and celebrate my ancestor(s)'},
        {value: 'preserve-history', label: 'Preserve my history for future generations'},
        {value: 'trace-tree', label: 'Trace my tree as far back as possible'},
        {value: 'share-stories', label: 'Share stories with family'},
        {value: 'other', label: 'Other, please specify:'},
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['contactUsFormIdState']),
  },
  methods: {
    submit() {
      this.loading = true;
      const payload = {
        id: this.contactUsFormIdState,
        interests: this.interests,
        comment: this.comment,
      };
      this.$store.dispatch('submitSurveyAction', payload).then(() => {
        this.$router.push({name: 'get-in-touch-success'});
      });
    },
  },
  components: {StandOutBlock, ContactUsTestimonial, McrButton},
  name: 'AncestorSurveyPage',
};
</script>

<style lang="scss" scoped>
.ancestor-survey-page {
  display: flex;
  justify-content: center;

  .content {
    display: flex;
    padding: $desktop-content-padding;
    max-width: $content-max-width;
    align-items: center;
    flex-grow: 1;

    .form {
      flex-grow: 1;
      display: flex;
    }

    .component_content {
      padding: 50px;
      flex: 1;

      .interest {
        margin: 15px 0;
      }
      #comments {
        margin: 20px 0;
        height: calc(4em + 3px);
        width: 100%;
        box-sizing: border-box;
      }
    }

    @media only screen and (max-width: $breakpoint-phablet) {
      padding: $mobile-se-content-padding;
      padding-top: 50px;

      .component_content {
        padding: 25px;
      }
    }

    @media only screen and (max-width: $main-menu-breakpoint) {
      flex-direction: column;
      align-items: stretch;
      .contact-testimonial {
        margin: 50px 0;
      }
    }
  }
}
</style>
