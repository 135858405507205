<template>
  <stand-out-block class="cta-unified-search">
    <h5>
      Discover records of {{ surname }}<span v-if="altSurname"> ({{ altSurname }})</span> ancestors and create your
      family tree
    </h5>
    <search-all-records-form
      :surname-first="surnameFirst"
      :surname-init="surname"
      :smart-redirect="true"
      :paywall="paywall"
      button-label="Find records"
      class="sub-section"
      @language-error="onLanguageError"
    ></search-all-records-form>
    <div v-if="error" class="error">{{ error }}</div>
  </stand-out-block>
</template>

<script>
import StandOutBlock from '@common/elements/layouts/StandOutBlock';

import SearchAllRecordsForm from '@/components/common/forms/SearchAllRecordsForm';

export default {
  props: {
    surname: String,
    altSurname: String,
    surnameFirst: Boolean,
    paywall: {type: Boolean, default: false},
  },
  data() {
    return {
      error: '',
    };
  },
  methods: {
    onLanguageError() {
      this.error = 'Please use the same language for both first and last names.';
    },
  },
  components: {StandOutBlock, SearchAllRecordsForm},
  name: 'ctaUnifiedSearch',
};
</script>

<style lang="scss" scoped>
.cta-unified-search {
  .error {
    margin-top: 10px;
  }
}
</style>
