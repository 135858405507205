<template>
  <div class="surname-variation-list">
    <detail-item
      v-for="value in variations"
      v-prerender-for="{listName: prerenderListName, valueName: 'dialect'}"
      :key="value.object_id"
      :label="value.region.english_name"
    >
      <span
        v-for="(spelling, index) in value.spellings"
        v-prerender-for="{listName: 'dialect.spellings', valueName: 'spelling'}"
      >
        <router-link :to="getVariantRoute(spelling)">{{ spelling }}</router-link>
        <span v-if="!isLastItem(index, value.spellings) || isTemplate" v-prerender-if="'not forloop.last'">, </span>
      </span>
    </detail-item>
  </div>
</template>

<script>
import detailItem from '@common/elements/layouts/detailsItem';

export default {
  props: {
    variations: Array,
    prerenderListName: String,
    isTemplate: Boolean,
  },
  methods: {
    getVariantRoute(value) {
      return {name: 'search-tool-surname-detail', query: {word: value}};
    },
    isLastItem(index, items) {
      return index === items.length - 1;
    },
  },
  components: {detailItem},
};
</script>

<style scoped lang="scss"></style>
